import { gql } from "@apollo/client";

// for fetching counts , weekly, monthly and yearly
export const GET_LABORS_COUNT = gql`
  mutation GET_LABORS_COUNT {
    getLaborCount {
      data
    }
  }
`;
